import { Controller } from "stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    const item = event.item;
    const id = item.dataset.id;
    const position = event.newIndex + 1;
    const address =item.dataset.address
    $.ajax({
      url: `/${address}/${id}/move`,
      type: 'PATCH',
      data: `position=${position}`
    });
  }
}



